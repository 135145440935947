<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog v-model="viewDialog" max-width="600px">
                <v-card>
                  <i
                    class="fas fa-times custom-times"
                    @click="closeViewDialog"
                  ></i>
                  <v-card-text>
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div
                          class="
                            symbol symbol-60 symbol-xxl-100
                            mr-5
                            align-self-start align-self-xxl-center
                          "
                        >
                          <div
                            class="symbol-label"
                            v-bind:style="{
                              backgroundImage:
                                'url(' + editedItem.image_public_url + ')',
                            }"
                          ></div>
                        </div>
                        <div>
                          <a
                            class="
                              font-weight-bolder font-size-h5
                              text-dark-75 text-hover-primary
                            "
                          >
                            {{ editedItem.title }}</a
                          >
                        </div>
                      </div>
                      <div class="py-9">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Description:</span
                          >
                          <a
                            class="text-muted text-hover-primary"
                            v-html="editedItem.description"
                          ></a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Short Description:</span
                          >
                          <a
                            class="text-muted text-hover-primary"
                            v-html="editedItem.short_description"
                          ></a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Long Description:</span
                          >
                          <a
                            class="text-muted text-hover-primary"
                            v-html="editedItem.long_description"
                          ></a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Praise:</span>
                          <a
                            class="text-muted text-hover-primary"
                            v-html="editedItem.praise"
                          ></a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Content Warning:</span
                          >
                          <a
                            class="text-muted text-hover-primary"
                            v-html="editedItem.content_warning"
                          ></a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">ASIN:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.asin
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">ISBN:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.isbn
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Price:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.price
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Best seller:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.best_seller
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">On sale:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.on_sale == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Award winning:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.award_winning == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Set product as new from date:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.new_from_date
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Set product as new to date:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.new_to_date
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Release date:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.release_date
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Enabled:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.enabled == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Featured:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.featured == 1 ? "Yes" : "No"
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Visibility:</span>
                          <a class="text-muted text-hover-primary">
                            <span v-if="editedItem.visibility == 0"
                              >Not Visible Individually</span
                            >
                            <span v-if="editedItem.visibility == 1"
                              >Catalog</span
                            >
                            <span v-if="editedItem.visibility == 2"
                              >Search</span
                            >
                            <span v-if="editedItem.visibility == 3"
                              >Catalog, Search</span
                            >
                          </a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Sexuality:</span>
                          <a class="text-muted text-hover-primary">
                            <span v-if="editedItem.sexuality == 0">F/F</span>
                            <span v-if="editedItem.sexuality == 1">M/F</span>
                            <span v-if="editedItem.sexuality == 2">M/M</span>
                            <span v-if="editedItem.sexuality == 3">Menage</span>
                            <span v-if="editedItem.sexuality == 4"
                              >Multiple</span
                            >
                          </a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Send browsed product autoresponder:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.send_browsed_product_autoresponder == 1
                              ? "Yes"
                              : "No"
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Page count:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.page_count
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Amazon standard identification number:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.amazon_standard_identification_number
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Meta title:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.meta_title
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Meta keywords:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.meta_keywords
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Meta description:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.meta_description
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Series:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.series ? editedItem.series.title : ""
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Order in series:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.order_in_series
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Author:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.author_names
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Book lengths:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.book_lengths_filter_value_names
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Imprints:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.imprints_filter_value_names
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Genres:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.genres_filter_value_names
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Tropes:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.tropes_filter_value_names
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Character age:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.character_age_filter_value_names
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2">Categories:</span>
                          <a class="text-muted text-hover-primary">{{
                            editedItem.category_names
                          }}</a>
                        </div>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            mb-2
                          "
                        >
                          <span class="font-weight-bold mr-2"
                            >Related books:</span
                          >
                          <a class="text-muted text-hover-primary">{{
                            editedItem.related_book_names
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                  <v-col cols="12">
                    <div
                      v-if="errors != undefined && errors.length >= 0"
                      role="alert"
                      v-bind:class="{ show: errors.length }"
                      class="alert fade alert-danger"
                    >
                      <div
                        class="alert-text"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>

              <v-dialog v-model="priceDialog" max-width="600px">
                <v-card>
                  <i class="fas fa-times custom-times" @click="closePriceDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id"
                      >Edit price for {{ editedItem.title }}</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Price</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                              ref="form"
                              v-model="validationRules.valid"
                              lazy-validation
                            >
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.price"
                                    :counter="10"
                                    :rules="validationRules.priceRules"
                                    label="Price (USD)*"
                                    required
                                  ></v-text-field>
                                </v-col>                                
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <ModuleMedia
                          moduleType="image"
                          moduleOf="book"
                          moduleMultiple="false"
                          :moduleImages="moduleImages"
                          :moduleImagesOld="moduleImagesOld"
                        ></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closePriceDialog"
                      >Cancel</v-btn
                    >
                    <v-btn
                      :disabled="!validationRules.valid"
                      color="blue darken-1"
                      text
                      @click="saveItemPrice(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                      v-if="errors != undefined && errors.length >= 0"
                      role="alert"
                      v-bind:class="{ show: errors.length }"
                      class="alert fade alert-danger"
                    >
                      <div
                        class="alert-text"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialog" max-width="1200px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                      color="primary"
                      dark
                      class="ml-auto ma-3 mr-1"
                      @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                      v-if="hasPermission('books.create')"
                      color="primary"
                      dark
                      class="ma-3 ml-0"
                      v-on="on"
                    >
                      <i class="flaticon2-plus text-white"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id"
                      >Edit {{ editedItem.title }}</span
                    >
                    <span v-else>Create Book</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Details</v-tab>
                      <v-tab>Media</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                              ref="form"
                              v-model="validationRules.valid"
                              lazy-validation
                            >
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.title"
                                    :counter="255"
                                    :rules="validationRules.titleRules"
                                    label="Title*"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label>Description*</label>
                                  <editor
                                    :api-key="tinyKey"
                                    ref="et_editor"
                                    v-model="editedItem.description"
                                    :init="editorInit"
                                  />
                                  <br />
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label>Short Description*</label>
                                  <editor
                                    :api-key="tinyKey"
                                    ref="et_editor"
                                    v-model="editedItem.short_description"
                                    :init="editorInit"
                                  />
                                  <br />
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label>Long Description</label>
                                  <editor
                                    :api-key="tinyKey"
                                    ref="et_editor"
                                    v-model="editedItem.long_description"
                                    :init="editorInit"
                                  />
                                  <br />
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label>Praise</label>
                                  <editor
                                    :api-key="tinyKey"
                                    ref="et_editor"
                                    v-model="editedItem.praise"
                                    :init="editorInit"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label>Content Warning</label>
                                  <editor
                                    :api-key="tinyKey"
                                    ref="et_editor"
                                    v-model="editedItem.content_warning"
                                    :init="editorInit"
                                  />
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.price"
                                    :counter="10"
                                    :rules="validationRules.priceRules"
                                    label="Price (USD)*"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.best_seller"
                                    :counter="10"
                                    maxLength="10"
                                    label="Best seller"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select
                                    clearable
                                    v-model="editedItem.award_winning"
                                    :items="ques"
                                    item-text="title"
                                    item-value="value"
                                    label="Award winning"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <v-select
                                    clearable
                                    v-model="editedItem.on_sale"
                                    :items="ques"
                                    item-text="title"
                                    item-value="value"
                                    label="On sale"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.sale_price"
                                    :counter="10"
                                    label="Sale price"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <v-text-field
                                    clearable
                                    type="date"
                                    v-model="editedItem.sale_from_date"
                                    label="Sale from date"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <v-text-field
                                    clearable
                                    type="date"
                                    v-model="editedItem.sale_to_date"
                                    label="Sale to date"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.asin"
                                    :counter="30"
                                    label="ASIN"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                    @keypress="isNumber($event)"
                                    clearable
                                    v-model="editedItem.isbn"
                                    :counter="30"
                                    :rules="validationRules.isbnRules"
                                    label="ISBN*"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                    clearable
                                    type="date"
                                    v-model="editedItem.new_from_date"
                                    label="Set Product as New from Date"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                    clearable
                                    type="date"
                                    v-model="editedItem.new_to_date"
                                    label="Set Product as New to Date"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                    clearable
                                    type="date"
                                    v-model="editedItem.release_date"
                                    label="Release Date"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select
                                    clearable
                                    v-model="editedItem.enabled"
                                    :items="ques"
                                    :rules="validationRules.selectedRules"
                                    item-text="title"
                                    item-value="value"
                                    label="Enabled*"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select
                                    clearable
                                    v-model="editedItem.featured"
                                    :items="ques"
                                    item-text="title"
                                    item-value="value"
                                    label="Featured"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select
                                    clearable
                                    v-model="editedItem.visibility"
                                    :items="visibilities"
                                    :rules="validationRules.selectedRules"
                                    item-text="title"
                                    item-value="value"
                                    label="Visibility*"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select
                                    clearable
                                    v-model="editedItem.sexuality"
                                    :items="sexualities"
                                    item-text="title"
                                    item-value="value"
                                    label="Sexuality"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select
                                    clearable
                                    v-model="
                                      editedItem.send_browsed_product_autoresponder
                                    "
                                    :items="ques"
                                    item-text="title"
                                    item-value="value"
                                    label="Send Browsed Product Autoresponder"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                    @keypress="isNumber($event)"
                                    clearable
                                    v-model="editedItem.page_count"
                                    maxLength="10"
                                    :counter="10"
                                    label="Page count"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    clearable
                                    v-model="
                                      editedItem.amazon_standard_identification_number
                                    "
                                    maxLength="50"
                                    :counter="50"
                                    label="Amazon Standard Identification Number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.meta_title"
                                    maxLength="1000"
                                    :counter="1000"
                                    label="Meta Title"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea
                                    v-model="editedItem.meta_keywords"
                                    label="Meta Keywords"
                                    maxLength="1000"
                                    :counter="1000"
                                    clearable
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea
                                    v-model="editedItem.meta_description"
                                    label="Meta Description"
                                    maxLength="1000"
                                    :counter="1000"
                                    clearable
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    clearable
                                    :items="seriesFilter"
                                    v-model="editedItem.series_id"
                                    item-text="title"
                                    item-value="id"
                                    label="Series"
                                    chips
                                    small-chips
                                    deletable-chips
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.order_in_series"
                                    maxLength="5"
                                    :counter="5"
                                    label="Order in Series"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="editedItem.author_ids"
                                    :items="authorsFilter"
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Author"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="
                                      editedItem.book_lengths_filter_value_ids
                                    "
                                    :items="bookLengthsFilterValuesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Book Lengths"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="
                                      editedItem.imprints_filter_value_ids
                                    "
                                    :items="imprintsFilterValuesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Imprints"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="editedItem.genres_filter_value_ids"
                                    :items="genresFilterValuesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Genres"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="editedItem.tropes_filter_value_ids"
                                    :items="tropesFilterValuesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Tropes"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="
                                      editedItem.character_age_filter_value_ids
                                    "
                                    :items="characterAgesFilterValuesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Character age"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="editedItem.category_ids"
                                    :items="categoriesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Categories"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-autocomplete
                                    v-model="editedItem.related_book_ids"
                                    :items="booksFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    deletable-chips
                                    chips
                                    :search-input.sync="search"
                                    @change="search = ''"
                                    :loading="isBooksLoaded"
                                    cache-items
                                    small-chips
                                    label="Related Books(s) (You may also like) (min. 3 characters required)"
                                    multiple
                                    @keyup="booksFilterKeyUp"
                                  >
                                  </v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <ModuleMedia
                          moduleType="image"
                          moduleOf="book"
                          moduleMultiple="false"
                          :moduleImages="moduleImages"
                          :moduleImagesOld="moduleImagesOld"
                        ></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                      >Cancel</v-btn
                    >
                    <v-btn
                      :disabled="!validationRules.valid"
                      color="blue darken-1"
                      text
                      @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                      v-if="errors != undefined && errors.length >= 0"
                      role="alert"
                      v-bind:class="{ show: errors.length }"
                      class="alert fade alert-danger"
                    >
                      <div
                        class="alert-text"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>

              <hr style="margin: 0" />
              <v-skeleton-loader
                :loading="loading"
                v-if="isLoaded"
                type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Books ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field
                  @keydown.enter="searchQueryData"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalRecords"
                :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true,
                }"
                :loading="loading"
                v-show="!isLoaded"
                mobile-breakpoint="800"
                class="elevation-0"
              >
                <template v-slot:item.title="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.title"></span>
                  </div>
                </template>
                <template v-slot:item.enabled="{ item }">
                  <div class="text-truncate">
                    <span v-if="item.enabled == 0">No</span>
                    <span v-if="item.enabled == 1">Yes</span>
                  </div>
                </template>
                <template v-slot:item.featured="{ item }">
                  <div class="text-truncate">
                    <span v-if="item.featured == 0">No</span>
                    <span v-if="item.featured == 1">Yes</span>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ moment(item.created_at).fromNow() }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('books.edit')"
                          color="primary"
                          @click="showPriceDialog(item)"
                          dark
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-dollar-sign</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Price</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('books.edit')"
                          color="primary"
                          @click="showEditDialog(item)"
                          dark
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('books.delete')"
                          color="red"
                          dark
                          @click="deleteItem(item)"
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('books.view')"
                          color="warning"
                          dark
                          @click="viewItemDialog(item)"
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  BOOKS_LIST,
  CREATE_BOOK,
  UPDATE_BOOK,
  UPDATE_BOOK_PRICE,
  CLEAR_BOOK_ERRORS,
  DELETE_BOOK,
  GET_BOOK_DETAILS,
  BOOKS_SIMPLE_LIST,
} from "@/core/services/store/books.module";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";
import { bus } from "@/main";
import { AUTHORS_SIMPLE_LIST } from "@/core/services/store/authors.module";
import { SERIES_SIMPLE_LIST } from "@/core/services/store/series.module";
import { CATEGORIES_SIMPLE_LIST } from "@/core/services/store/categories.module";
import {
  GENRES_FILTERVALUES_LIST,
  IMPRINTS_FILTERVALUES_LIST,
  TROPES_FILTERVALUES_LIST,
  BOOKLENGTHS_FILTERVALUES_LIST,
  CHARACTERAGE_FILTERVALUES_LIST,
} from "@/core/services/store/filtervalues.module";

export default {
  name: "books",
  components: {
    ModuleMedia,
  },
  data() {
    return {
      editorInit: {
        branding: false,
        height: 500,
        menubar: false,
        images_dataimg_filter: function (img) {
          return img.hasAttribute("internal-blob");
        },
        images_upload_url: "image",
        automatic_uploads: false,
        plugins: [
          "advlist autolink lists code link image imagetools charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code wordcount fullscreen",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | code | link | image | fullscreen",
      },
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc",
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      moduleImagesOld: [],
      headerindex: "",
      headers: [
        {
          text: "Title",
          value: "title",
          name: "title",
          filterable: true,
          sortable: true,
        },
        {
          text: "Featured",
          value: "featured",
          name: "featured",
          filterable: false,
          sortable: true,
        },
        {
          text: "Enabled",
          value: "enabled",
          name: "enabled",
          filterable: false,
          sortable: true,
        },
        {
          text: "Best seller",
          value: "best_seller",
          name: "best_seller",
          filterable: false,
          sortable: true,
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
        },
        {
          text: "Action",
          value: "actions",
          name: "name",
          filterable: false,
          sortable: false,
        },
      ],
      validationRules: {
        valid: true,
        titleRules: [
          (v) => !!v || "This field is required",
          (v) =>
            (v && v.length <= 50) ||
            "Must be less than or equal to 200 characters",
          (v) => (v && v.length >= 3) || "Must be at least 3 characters",
        ],
        descriptionRules: [
          (v) => !!v || "This field is required",
          (v) => (v && v.length >= 6) || "Must be at least 6 characters",
        ],
        priceRules: [
          (v) => !!v || "This field is required",
          (v) =>
            (v && v.length <= 10) ||
            "Must be less than or equal to 10 characters",
          (v) => (v && v.length >= 1) || "Must be at least 1 characters",
        ],
        asinRules: [
          (v) => !!v || "This field is required",
          (v) =>
            (v && v.length <= 30) ||
            "Must be less than or equal to 30 characters",
          (v) => (v && v.length >= 3) || "Must be at least 3 characters",
        ],
        isbnRules: [
          (v) => !!v || "This field is required",
          (v) =>
            (v && v.length <= 30) ||
            "Must be less than or equal to 30 characters",
          (v) => (v && v.length >= 13) || "Must be at least 13 characters",
        ],
        selectedRules: [(v) => !!v || "This field is required"],
      },
      items: [],
      authorsFilter: [],
      seriesFilter: [],
      bookLengthsFilterValuesFilter: [],
      imprintsFilterValuesFilter: [],
      genresFilterValuesFilter: [],
      tropesFilterValuesFilter: [],
      characterAgesFilterValuesFilter: [],
      categoriesFilter: [],
      booksFilter: [],
      isBooksLoaded: false,
      dialog: false,
      viewDialog: false,
      priceDialog: false,
      editedItem: {},
      ques: [
        { value: "0", title: "No" },
        { value: "1", title: "Yes" },
      ],
      visibilities: [
        { value: "0", title: "Not Visible Individually" },
        { value: "1", title: "Catalog" },
        { value: "2", title: "Search" },
        { value: "3", title: "Catalog, Search" },
      ],
      sexualities: [
        { value: "0", title: "F/F" },
        { value: "1", title: "M/F" },
        { value: "2", title: "M/M" },
        { value: "3", title: "Menage" },
        { value: "4", title: "Multiple" },
      ],
    };
  },
  watch: {
    related_book_ids() {
      this.search = "";
    },
    params: {
      handler() {
        this.loadData().then((response) => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true,
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_BOOK_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.editedItem.description = "";
      if (!val) this.editedItem.short_description = "";
      if (!val) this.editedItem.long_description = "";
      if (!val) this.editedItem.praise = "";
      if (!val) this.editedItem.content_warning = "";
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
    },
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
        "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Books Management", route: "books-management" },
      { title: "Books" },
    ]);
    bus.$on("moduleImagesUploaded", (moduleImages) => {
      this.moduleImages = moduleImages.arr;
    });
    bus.$on("moduleImagesUploadedOld", (moduleImagesOld) => {
      this.moduleImagesOld = moduleImagesOld.arr;
    });
    this.getAuthors();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
          params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
          .map((key) => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
          "&search[value]=" +
          params.query +
          "&search[regex]=" +
          false +
          "&order[0][column]=" +
          (params.sortBy.length === 0 ? 4 : this.headerindex) +
          "&order[0][dir]=" +
          (params.sortDesc[0] ? "desc" : "asc");
        this.$store
          .dispatch(BOOKS_LIST, { q: query, columns: this.columns })
          .then((res) => {
            this.draw++;
            let items = res.records.data;
            let total = res.records.recordsFiltered;
            this.loading = !this.loading;
            this.isLoaded = !this.isLoaded;
            resolve({
              items,
              total,
            });
          });
      });
    },
    refreshData() {
      this.loadData().then((response) => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getAuthors();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showPriceDialog(item) {
      this.editedItem = item || {};
      this.priceDialog = true;
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_BOOK_DETAILS, item.id).then((data) => {
        let bookImages = [];
        this.editedItem = data.records || {};
        this.booksFilter = data.records.related_books;
        if (data.records.image_public_url !== null) {
          bookImages.push(data.records);
        }
        this.moduleImagesOld = bookImages;
        this.dialog = !this.dialog;
      });
    },
    viewItemDialog(item) {
      this.$store.dispatch(GET_BOOK_DETAILS, item.id).then((data) => {
        this.editedItem = data.records || {};
        this.viewDialog = !this.viewDialog;
      });
    },
    closePriceDialog() {
      this.priceDialog = false;
    },
    closeViewDialog() {
      this.viewDialog = !this.viewDialog;
    },
    closeDialog() {
      if (this.moduleImages.length > 0) {
        let mFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
              .dispatch("delete_uploaded_image", {
                files: mFiles,
                type: "modal",
              })
              .then(() => {
                that.moduleImages = [];
                that.dialog = false;
                bus.$emit("moduleImagesDeletedByModal");
              });
          },
        });
      } else {
        this.dialog = !this.dialog;
        // bus.$emit('moduleModalClosed');
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_BOOK;
        let id = item.id;
        this.$set(item, "image", this.moduleImages);
        let data = {
          data: item,
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_BOOK;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_BOOK_ERRORS);
        // save the record
        this.$store.dispatch(method, { slug: id, data: data.data }).then(() => {
          this.loadData().then((response) => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleImagesDeletedByModal");
        });
      }
    },
    saveItemPrice(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = UPDATE_BOOK_PRICE;
        let id = item.id;
        let data = {
          id: item.id,
          price: item.price,
        };
        this.$store.dispatch(CLEAR_BOOK_ERRORS);
        // save the record
        this.$store.dispatch(method, { slug: id, data }).then(() => {
          this.editedItem = {};
          this.priceDialog = false;
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.title + "'?",
        clr: "red",
        callback: function () {
          let id = item.id;
          this.$store
            .dispatch(DELETE_BOOK, {
              book_id: id,
            })
            .then(() => {
              that.loadData().then((response) => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
        },
      });
    },
    getAuthors() {
      this.$store.dispatch(AUTHORS_SIMPLE_LIST).then((data) => {
        this.authorsFilter = data.records.authorsList;
        this.getSeries();
      });
    },
    getSeries() {
      this.$store.dispatch(SERIES_SIMPLE_LIST).then((data) => {
        this.seriesFilter = data.records.seriesList;
        this.getCategories();
      });
    },
    getCategories() {
      this.$store.dispatch(CATEGORIES_SIMPLE_LIST).then((data) => {
        this.categoriesFilter = data.records.categoriesList;
        this.getImprintsFilterValues();
      });
    },
    getImprintsFilterValues() {
      this.$store.dispatch(IMPRINTS_FILTERVALUES_LIST).then((data) => {
        this.imprintsFilterValuesFilter = data.records.imprintsFilterValuesList;
        this.getBookLengthsFilterValues();
      });
    },
    getBookLengthsFilterValues() {
      this.$store.dispatch(BOOKLENGTHS_FILTERVALUES_LIST).then((data) => {
        this.bookLengthsFilterValuesFilter =
          data.records.bookLengthsFilterValuesList;
        this.getGenresFilterValues();
      });
    },
    getGenresFilterValues() {
      this.$store.dispatch(GENRES_FILTERVALUES_LIST).then((data) => {
        this.genresFilterValuesFilter = data.records.genresFilterValuesList;
        this.getTropesFilterValues();
      });
    },
    getTropesFilterValues() {
      this.$store.dispatch(TROPES_FILTERVALUES_LIST).then((data) => {
        this.tropesFilterValuesFilter = data.records.tropesFilterValuesList;
        this.getCharacterAgeFilterValues();
      });
    },
    getCharacterAgeFilterValues() {
      this.$store.dispatch(CHARACTERAGE_FILTERVALUES_LIST).then((data) => {
        this.characterAgesFilterValuesFilter =
          data.records.characterAgeFilterValuesList;
      });
    },
    booksFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isBooksLoaded = true;
        this.$store
          .dispatch(BOOKS_SIMPLE_LIST, {
            search: "all",
            q: e.target.value,
          })
          .then((data) => {
            this.booksFilter = data.records.booksList;
            this.isBooksLoaded = false;
          });
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery,
      };
    },
    ...mapState({
      errors: (state) => state.books.errors,
    }),
  },
};
</script>
